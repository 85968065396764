<template>
  <v-footer
    class="footer-simulator mobile-footer-simulator"
  >
    <v-card
      flat
      tile
      width="100%"
      class="red text-center footer-simulator-body"
    >

      <v-card-text class="d-flex mobile-footer-v-text justify-space-between white--text">
        <v-btn
          plain
          color="#F7F072"
          @click="$router.push('/home')"
        >
          <v-icon size="20" class="mr-1">
            {{ icons.mdiHome }}
          </v-icon>
          <strong>Ir a la plataforma Iluminar</strong>
        </v-btn>
        <div class="d-flex flex-column align-end">
          <strong>COPYRIGHT © {{ new Date().getFullYear() }} —
            <a href="https://iluminarlaboratorio.com.ar" class="custom-link"> Iluminar </a>
          </strong>
          <span>— Powered by
            <a
                href="https://letscode.ar"
                class="custom-link"
                >
                Let's Code
                </a>
          </span>
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import {
  mdiHome,
} from '@mdi/js'
import { useRouter } from '@core/utils'

export default {
  setup() {

    return {
      icons: {
        mdiHome,
      },
    }
  },
}
</script>

<style scoped>
  .footer-simulator {
    position: sticky;
    bottom: 0;
    background-color: #6D8CD4;
    height: 6rem;
    z-index: 3;
  }

  .footer-simulator-body {
    background-color: #6D8CD4;
    box-shadow: none;
  }

  .custom-link {
    color: rgb(226, 226, 226);
    text-decoration: none;
  }

  .custom-link:hover {
    color: rgb(28 30 168 / 47%);
  }

  @media (max-width: 600px) {
  .mobile-footer-simulator {
    padding: 6px 0
  }

  .mobile-footer-v-text {
    display: block !important;
  }

  .mobile-footer-v-text div {
    justify-content: center !important;
    align-items: center !important;
    padding: 5px 0
  }
}
</style>
