<template>
  <div class="simulator overflow-y d-flex flex-column">
    <v-row>
      <v-col
        cols="0"
        md="6"
        lg="6"
        class="first-section mx-auto d-flex flex-column justify-space-between"
      >
        <v-card 
          class="d-flex flex-column"
          style="height: 100%; position: relative;"
        >
        <a href="/">
          <img 
            src="../assets/logos/SVG/Recurso 1.svg" alt=""
            class="img-logo cursor-pointer"
            
          >
        </a>
          <div class="simulator-header">
            <v-card-text class="text-center text-h4 mt-6 header-title" style="position: relative; z-index: 2;">Simulador de costos de Análisis</v-card-text>
            <v-card-text 
              class="text-center aclaration-text"
            >
              Te invitamos a que conozcas todos los análisis que hacemos en nuestro laboratorio, y puedas calcular el costo aproximado de un análisis clínico. Selecciona las determinaciones que necesites y automáticamente verás el valor total. 
              La toma de muestras en laboratorio tiene un costo adicional.
            </v-card-text>
          </div>
          <img 
            src="../assets/images/calculator.jpg" alt=""
            class="background-img"
          >
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
        class="second-section mx-auto d-flex flex-column justify-space-between overflow-auto"
      >
  
        <v-form 
          class="determinations-container" 
          ref="determinationDataForm"
          style="padding: 1rem 0 0;"
        >
          <AppCardActions action-collapse>
            <template slot="title"> Perfiles </template>
            <!-- <v-card-text>
              Perfiles body
            </v-card-text> -->
            <v-card-text>
              <v-row dense no-gutters>
                <v-col 
                  class="d-flex v-list-item" 
                  v-for="profile in profilesList.slice(0, 9)" 
                  :key="profile.name" 
                  cols="6"
                  md="6"
                  lg="4"
                >
                  <v-checkbox v-if="profile.id !== 8"
                    v-model="profile.checked" 
                    @click="selectProfile(profile)"
                    dense
                  />

                  <v-checkbox v-else
                    v-model="profile.checked" 
                    disabled
                    dense
                  />

                  <v-card-title 
                    class="caption px-0 py-2 mb-2 cursor-pointer" 
                    @click="selectProfile(profile, true)"
                  >
                    <v-tooltip right open-delay="100">
                      <template v-slot:activator="{ on, attrs }">
                        <span 
                          class="text-body-1"
                          v-on="on" 
                          v-bind="attrs"
                        >
                          {{ profile.title }}  
                        </span>
                      </template>
                      <span> 
                        <div v-for="determination in profile.determinations">
                          {{ determination.label }}
                        </div>
                      </span>
                    </v-tooltip>
                  </v-card-title>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="font-italic"> * Para precio actualizado del perfil Tiroideo comunicarse con el laboratorio</v-card-text>

          </AppCardActions>
          <AppCardActions action-collapse collapsed>
            <template slot="title"> Análisis Individuales </template>
            <v-card-text>
              <v-row dense no-gutters>
                <v-col cols="12" md="12">
                  <v-text-field
                  v-model="searchQuery"
                  @keyup="handleSearch"
                  :append-icon="icons.mdiMagnify"
                  placeholder="Buscar..."
                  outlined
                  hide-details
                  dense
                  class="user-search me-3 mb-2"
                  >

                  </v-text-field>
                </v-col>
                <v-col
                  class="d-flex v-list-item"
                  v-for="determination in allDeterminations"
                  :key="determination.id"
                  cols="6"
                  md="6"
                  lg="4"
                >
                  <v-checkbox
                    :input-value="selectedDeterminationsIDs.includes(determination.id)"
                    @change="updateSelectedDeterminations(determination, $event)"
                    dense
                  >
                  </v-checkbox>
                  <v-card-title 
                    class="caption px-0 py-2 mb-2 cursor-pointer" 
                    @click="updateSelectedDeterminations(determination, !selectedDeterminationsIDs.includes(determination.id))"
                  >
                    <v-tooltip top open-delay="100">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ determination.readable }}
                        </span>
                      </template>
                      <span>$ {{ determination.price }}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-col>
              </v-row>
            </v-card-text>
          </AppCardActions>
        </v-form>
      </v-col>


      <v-col
        class="total-container"
        cols="12"
        md="6"
        lg="6"
      >
        <v-card class="pr-4 total-card-body d-flex" >
          <v-btn icon class="ma-4 refresh-btn" @click="refreshForm"> 
            <v-icon color="white"> {{ icons.mdiRefreshCircle }}</v-icon>
          </v-btn>

          <v-card-text class="text-end text-h5 white--text mt-auto" >Total: ${{ total_price_calculated }}</v-card-text>
        </v-card> 
      </v-col>
    </v-row>


    <FooterSimulator />

    
  </div>
</template>

<script>
import profiles from '@/utils/profiles'
import RequestFormBody from '@/components/LabRequest/RequestFormBody.vue'
import FooterSimulator from '@/components/footer-simulator/FooterSimulator.vue'
import debounce from '../plugins/debounce.js'
import AppCardActions from "@core/components/app-card-actions/AppCardActions.vue";
import { 
  mdiRefreshCircle,
  mdiMagnify
} from "@mdi/js";

export default {
  data: () => {
    return {
      searchQuery: '',
      total: 0,
      profilesList: [],
      editingRequest: {},
      selectedDeterminations: [],
      profilePrices: [],
      category0List: [],
      icons: {mdiRefreshCircle, mdiMagnify},
      allDeterminations: [],
      profileDeterminations: []

    };
  },
  mounted() {
    this.profilesList = JSON.parse(JSON.stringify(profiles))
    // console.log(this.profilesList);
    this.getDeterminationsList()
    document.getElementsByTagName('html')[0].style.overflow = 'hidden'
  },
  beforeUnmount() {
    document.getElementsByTagName('html')[0].style.overflow = 'auto'
  },
  components: {
    RequestFormBody,
    AppCardActions,
    FooterSimulator
  },
  methods: {
    async getDeterminationsList() {
      const data = {
        paginated: false,
        // summary: true,
        simulator: true,
        search: this.searchQuery
      };
      try {
        const response = await this.$api.getDeterminationsList(data);
        
        this.allDeterminations = response
        if(!this.profileDeterminations.length) {
          this.profileDeterminations = response
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    handleSearch: debounce(function() {
      this.getDeterminationsList();
    }, 400),

    selectProfile(profile, from_title = false) {
      
      if(profile.id !== 8) {

        if (from_title) profile.checked = !profile.checked
        
        const is_checked = profile.checked
                  
        if (is_checked) {

          const prices = []
          profile.determinations.forEach(determination => {
            const founded = this.profileDeterminations.find(el => el.name === determination.name)
            prices.push(founded.price)
          });

          const profilePrice = prices.reduce((acc, cur) => { return acc += cur }, 0)
          
          this.profilePrices.push({profile: profile.id, price: profilePrice});

        } else {
          const index = this.profilePrices.findIndex(item => item.profile === profile.id)
          if (index !== -1) {
            const removed = this.profilePrices.splice(index, 1)
          }
        }
      } 
    },
    
    updateSelectedDeterminations(determination, isChecked) {
      if (isChecked) {
        // agregar IDs a la matriz selectedDeterminations si no existen todavía
        this.selectedDeterminations.push(determination);
      } else {
        // Eliminar IDs a la matriz selectedDeterminations si ya existen
        const index = this.selectedDeterminations.indexOf(determination);
        if (index > -1) {
          this.selectedDeterminations.splice(index, 1);
        }
      }
    },
    refreshForm() {
      this.$refs.determinationDataForm.reset();
      this.profilePrices = []
    }
  },


  computed: {

    total_price_calculated() {

      const determination = this.selectedDeterminations.reduce(
        (acc, cur) => {
          return acc + cur.price
        }, 0
      )

      const profile = this.profilePrices.reduce(
        (acc, cur) => {
          return acc + cur.price
        }, 0
      )
      return determination + profile ;
    },

    selectedDeterminationsIDs() {
      return this.selectedDeterminations.map(el => el.id)
    }
  },
}

</script>

<style scoped>
  .simulator {
    height: 100%; 
    width: 100%; 
    position: relative;
  }

  .first-section {
    padding: 0;
    z-index: 2;
    position: relative;
  }

  .aclaration-text {
    padding: 0 0 16px; 
    max-width: 632px; 
    padding: 0 1rem 1rem;
    margin: 0 auto; 
    position: relative; 
    z-index: 2;
  }

  .background-img {
    position: absolute;
    z-index: 0;
    bottom: 2rem;
    left: 20%;
    width: 580px;
    max-width: 65%;
  }

  .second-section {
    padding: 0;
    height: 100%; 
    max-height: calc(94vh - 6rem);
    position: relative;
  }

  @media (max-width: 960px) {
    .background-img {
      display: none;
    }
    .second-section {
      max-height: calc(59vh - 6rem);
      padding-bottom: 4rem;
    }
  }

  .img-logo {
    margin: 0 auto; 
    position: relative; 
    z-index: 2; 
    width: 600px;
  }


  @media (max-width: 1175px) {
    .img-logo {
      width: 100%;
    }
  }

  /* Height Adjustment */
  @media (max-height: 900px) {
    .background-img {
      width: 400px;
      /* display: none; */
    }
  }

  @media (max-height: 770px) {
    .background-img {
      display: none;
    }
    .simulator-header{
      margin: auto;
    }

    .header-title{
      font-size: 1.7rem !important;
    }
  }

  .determinations-container {
    padding: 2rem 1rem 2rem; 
  }

  .total-container {
    position: absolute;
    height: 6rem;
    bottom: 4rem;
    right: 0;
    padding: 0;
  }

  .total-container, .total-card-body {
    background-color: #750D7C;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .refresh-btn:hover {
    transform: scale(1.2);
  }
</style>
